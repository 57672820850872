import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import Layout from "./layout";
import { extendTheme } from "@chakra-ui/react";

export const wrapPageElement = ({ element }) => {
  const theme = extendTheme({
    colors: {
      canadianred: {
        50: "#fbe9e9",
        100: "#f4bebe",
        200: "#ed9392",
        300: "#e56866",
        400: "#de3d3b",
        500: "#da2725",
        600: "#c42321",
        700: "#991b1a",
        800: "#6d1312",
        900: "#410c0b",
      },
      canadianwhite: {
        50: "#f2f2f2",
        100: "#d9d9d9",
        200: "#bfbfbf",
        300: "#a6a6a6",
        400: "#8c8c8c",
        500: "#fff",
        600: "#737373",
        700: "#595959",
        800: "#404040",
        900: "#262626",
      },
    },
    fonts: { heading: "Montserrat", body: "Montserrat" },
  });
  return (
    <ChakraProvider theme={theme}>
      <Layout>{element}</Layout>
    </ChakraProvider>
  );
};
